var Ajax = require('./ajax');

module.exports = {
    url: {
        getHomeInfo: '/home/study-info',
        acceptPrivacyPolicy: 'accept-privacy-policy'
    }
};

Ajax.add(module.exports, module.exports.url);
