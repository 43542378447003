<template>
    <NCMain class="home" :navNoSplit="true" downloadText="下载牛客APP  1w+企业HR热招中">
        <Swipe v-gio="h5bitData()" class="swiper" height="117" :loop="true" :duration="500">
            <SwipeItem v-for="(item, index) in bannerList" :key="index">
                <VanImage :src="item.image" radius="10" fit="contain" lazy-load class="banner-image" @click.native="actionRoute(item.url)" />
            </SwipeItem>
        </Swipe>

        <div v-if="noticeBarShow" class="hide-txt flex-row-start fs-s p-1 color-green bg-light-green" @click="actionNoticeBar">
            <div class="mr-2 flex-none"><i class="icon-laba"></i></div>
            <div class="hide-txt flex-auto">你的资料未完善，部分功能无法使用。</div>
            <div slot="right flex-none"><span>立刻设置 </span><i class="fs-xs icon-arrow-right"></i></div>
        </div>

        <div class="nav">
            <div v-for="(item, index) in rowData" :key="`col${index}`" v-gio="h5bitData(item)" class="col-item" @click="actionRoute(item.link, item.title)">
                <div class="nav-box" :style="`background-image: url(${item.bg})`">
                    <span>{{ item.title }}</span>
                </div>
            </div>
        </div>

        <List />

        <Confirm v-model="confirmShow" :close-on-confirm="false" :close-on-cancel="false" :title="confirmTitle" :content="confirmContent" @on-cancel="actionConfirmCancel" @on-confirm="actionConfirmSubmit"> </Confirm>
        <confirm v-model="tipsConfirmShow" :title="tipsConfirmTitle" :content="tipsConfirmContent" @on-cancel="actionTipsConfirmCancel" @on-confirm="actionTipsConfirmSubmit"> </confirm>
    </NCMain>
</template>

<script>
import {Confirm} from '@ncfe/nc.vux';
import NCMain from '@/js/cpn/main/NCMain';
import {Image as VanImage, Swipe, SwipeItem} from 'vant';
import 'vant/lib/image/style/index';
import {Observer} from '@ncfe/gio.directive';
import {GioDiscuss} from '@/gio';
const {gio} = require('@ncfe/gio.lib');
let UserAction = require('@/js/lib/action/user');
let HomeAction = require('@/js/lib/action/home');
let LoginAction = require('@/js/lib/action/login');
const cookie = require('@ncfe/nc.cookie');

export default {
    components: {
        NCMain,
        VanImage,
        List: () => import('./cpn/List.vue'),
        Swipe,
        SwipeItem,
        Confirm
    },
    directives: {
        gio: new Observer()
    },
    data: fData,
    mounted: fMounted,
    methods: {
        initData: fInitData,
        actionNoticeBar: fActionNoticeBar,
        actionConfirmCancel: fActionConfirmCancel,
        actionConfirmSubmit: fActionConfirmSubmit,

        actionTipsConfirmCancel: fActionTipsConfirmCancel,
        actionTipsConfirmSubmit: fActionTipsConfirmSubmit,

        actionRoute,
        h5bitData
    }
};

function h5bitData(oItem) {
    let value = {
        h5bitType_var: '顶部banner'
    };
    if (oItem) {
        value.h5bitType_var = oItem.title;
    }
    return {
        name: 'h5bitView',
        value: {
            ...value
        }
    };
}

function fData() {
    return {
        user: null,
        window: window,
        bannerList: [],
        clock: {},
        noticeBarShow: false,
        confirmShow: false,
        confirmTitle: '',
        confirmContent: '',

        tipsConfirmShow: false,
        tipsConfirmTitle: '',
        tipsConfirmContent: '您确定不同意吗？不同意更新后的隐私政策将无法使用牛客网网站及应用的全部功能',

        rowData: []
    };
}

function fMounted() {
    let that = this;
    that.initData();
    that.noticeBarShow = window.globalInfo.ownerId && !window.globalInfo.isCompleteInfo;

    UserAction.getInfo({
        type: 'GET',
        call: oResult => {
            that.user = oResult.data;
            if (that.user.needacceptPrivacyPolicy) {
                that.confirmShow = true;
                that.confirmTitle = that.user.privacyPoliceTitle;
                that.confirmContent = that.user.privacyPoliceContent;
            }
        },
        error: oResult => (that.error = oResult.msg)
    });
    gio('track', 'h5PageView', {pageName_var: 'h5首页', channelPut_var: cookie.get('channelPut') || ''});
}

async function fInitData() {
    let that = this;
    that.rowData = [
        {
            title: '专项练习',
            link: '/itest/menu',
            bg: $ncimage('/images/home/practice.png')
        },
        {
            title: '公司套题',
            link: '/paper/menu',
            bg: $ncimage('/images/home/company.png')
        },
        {
            title: '错题练习',
            link: '/itest/wrong-set',
            bg: $ncimage('/images/home/wrong.png')
        }
    ];
    try {
        var oResult = await HomeAction.getHomeInfo({type: 'GET'});
        let oData = oResult.data;
        that.bannerList = oData.advertises?.length ? oData.advertises : [{image: $ncimage('/images/default-banner.jpg'), url: '/'}];
        that.clock = oData.clockInfo || {};
    } catch (e) {}
}

function actionRoute(url, title = '') {
    if (!url) return;
    if (!url.startsWith('/') && !url.startsWith('http')) url = `/${url}`;
    GioDiscuss.h5bitClick({
        h5bitType_var: title ? title : '顶部banner'
    });
    window.location.href = url;
}

function fActionNoticeBar() {
    let that = this;
    window.location.href = '/completeness?callback=' + window.encodeURIComponent(`/`);
}

function fActionConfirmCancel() {
    let that = this;
    that.tipsConfirmShow = true;
}

function fActionConfirmSubmit() {
    let that = this;
    HomeAction.acceptPrivacyPolicy({
        type: 'POST',
        call: oData => (that.confirmShow = false),
        error: oError => that.$vux.toast.text(oError.msg, 'middle')
    });
}

function fActionTipsConfirmCancel() {
    let that = this;
    that.confirmShow = true;
}

function fActionTipsConfirmSubmit() {
    let that = this;
    LoginAction.logout({
        type: 'POST',
        call: oResult => (window.location.href = '/'),
        error: oResult => that.$vux.toast.text(oResult.msg, 'middle')
    });
}
</script>
<style lang="scss" scoped>
$clockHeight: 60px;
$flex-width: calc(33.333% - 10px);
.home {
    background: #f9f9f9 !important;
    .swiper {
        margin: 12px;
        .banner-image,
        .banner-image img {
            height: 100%;
        }
    }

    .nav {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin: 16px 12px;
        .col-item {
            flex: 0 0 $flex-width;
            padding-bottom: calc(#{$flex-width} / 1.64);
            overflow: hidden;
            margin-right: 10px;
            margin-top: 10px;
            position: relative;
            box-sizing: border-box;
            &:nth-child(3n) {
                margin-right: 0;
            }
            &:nth-child(-n + 3) {
                margin-top: 0;
            }
        }
        .nav-box {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-position: center;
            background-size: cover;
            border-radius: 10px;
            span {
                display: block;
                width: 32px;
                font-size: 16px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                line-height: 21px;
                padding-top: 8px;
                padding-left: 8px;
            }
        }
    }
}
</style>
